import React from "react";

function Test2() {
  return (
    <div className="bg-white shadow-md col-span-12 md:col-span-8 lg:col-span-6
      md:col-start-3 lg:col-start-4 md:p-6 p-4"
    >
      <h1 className="text-xl md:text-2xl font-bold">Private Route!!! </h1>
    </div>
  )
}

export default Test2;
